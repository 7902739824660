import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckboxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useMemo } from "react";
import { Product } from "./hooks";

interface Props {
  products: Product[];
  selectedProducts: Product[];
  onChange: (products: Product[]) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen: boolean;
  disabled?: boolean;
}

function ProductsAutocomplete({
  products,
  selectedProducts,
  onChange,
  onClose,
  onOpen,
  isOpen,
  disabled,
}: Props) {
  const sortedProducts = useMemo(
    () => [...products].sort((a, b) => a.title.localeCompare(b.title)),
    [products],
  );

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      options={sortedProducts}
      disableCloseOnSelect
      getOptionLabel={(option: Product) => option.title}
      onChange={(_event, value) => onChange(value)}
      value={selectedProducts}
      onOpen={onOpen}
      onClose={onClose}
      open={isOpen}
      sx={{
        zIndex: 3,
        position: "relative",
        p: isOpen ? 2 : 0,
        borderRadius: 1,
      }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckboxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => (
        <TextField {...params} label="Products" placeholder="Products" />
      )}
    />
  );
}

export default ProductsAutocomplete;
