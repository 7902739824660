import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Container,
  Fade,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "./App.css";
import AppMenu from "./AppMenu";
import ContactForm from "./ContactForm";
import MessageContainer from "./MessageContainer";
import ProductCard from "./ProductCard";
import ProductsAutocomplete from "./ProductsAutocomplete";
import { Product, usePDFDocument } from "./hooks";
function App() {
  const onGenerateError = (error: unknown) => {
    console.error(error);
  };

  const {
    products,
    store,
    isInitializing,
    generateMutation,
    isInitializationError,
    retry,
    isReloading,
    reload,
  } = usePDFDocument({
    onGenerateError,
  });

  const [isProductAcOpen, setIsProductAcOpen] = useState<boolean>(false);

  const onProductAcOpen = () => {
    setIsProductAcOpen(true);
  };

  const onProductAcClose = () => {
    setIsProductAcOpen(false);
  };

  const isGenerating = generateMutation.status === "pending";

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          overflowY: store.isInitialized ? "scroll" : undefined,
        }}
      >
        <Fade in={isProductAcOpen}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1,
              bgcolor: "text.disabled",
              opacity: 0.5,
            }}
          />
        </Fade>

        <AppMenu
          isLoadingProducts={isInitializing ?? isReloading}
          onReloadProducts={reload}
        />

        {isInitializing && (
          <MessageContainer>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Loading Products
            </Typography>
            <LinearProgress
              sx={{
                width: "50%",
              }}
            />
          </MessageContainer>
        )}

        {isInitializationError && (
          <MessageContainer>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Error Loading Products
            </Typography>
            <Button
              variant="contained"
              onClick={retry}
              disabled={isInitializing}
            >
              Retry
            </Button>
          </MessageContainer>
        )}

        {store.isInitialized && (
          <Container
            maxWidth="md"
            sx={{
              p: 2,
              mt: 1,
            }}
          >
            <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button
                sx={{ mr: 2 }}
                onClick={store.clear}
                disabled={isGenerating}
              >
                Clear
              </Button>
              <LoadingButton
                variant="contained"
                loading={isGenerating}
                onClick={() => generateMutation.mutate()}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                disabled={
                  isGenerating || store.document.selectedProducts.length === 0
                }
              >
                Generate PDF
              </LoadingButton>
            </Box>
            <Box component="form" noValidate sx={{ mb: 4 }}>
              <ContactForm
                name={store.document.contactName}
                email={store.document.contactEmail}
                phone={store.document.contactPhone}
                onNameChange={store.setContactName}
                onEmailChange={store.setContactEmail}
                onPhoneChange={store.setContactPhone}
                disabled={isGenerating}
              />

              <ProductsAutocomplete
                products={products ?? []}
                selectedProducts={store.document.selectedProducts}
                onChange={store.setSelectedProducts}
                isOpen={isProductAcOpen}
                onClose={onProductAcClose}
                onOpen={onProductAcOpen}
                disabled={isGenerating}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                {store.document.selectedProducts.map((product: Product) => (
                  <ProductCard
                    disabled={isGenerating}
                    key={product.id}
                    product={product}
                    customPrice={store.getCustomPrice(product)}
                    setCustomPrice={store.setCustomPrice}
                    removeCustomPrice={store.removeCustomPrice}
                    removeProduct={() => store.removeProduct(product)}
                  />
                ))}
              </Stack>
            </Box>
          </Container>
        )}
      </Box>
    </>
  );
}

export default App;
