import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

function MessageContainer({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        transform: "translateY(-25%)",
      }}
    >
      {children}
    </Box>
  );
}

export default MessageContainer;
