import { Autocomplete, Grid, TextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

type DefaultContact = {
  name: string;
  email: string;
  phone: string;
};

const defaultContacts: DefaultContact[] = [
  {
    name: "Satoshi Urakawa, Vice President of Sales",
    email: "satoshiu@sakeone.com",
    phone: "510-759-2738",
  },
  {
    name: "Jack Lien, Senior Sales Manager",
    email: "jackl@sakeone.com",
    phone: "360-931-1541",
  },
  {
    name: "Midori Nakazawa, Regional Sales Manager",
    email: "midorin@sakeone.com",
    phone: "917-345-9731",
  },
  {
    name: "Masa Fukuda, Regional Sales Manager",
    email: "masaf@sakeone.com",
    phone: "503-422-9893",
  },
  {
    name: "Haru Takase, Sales Manager",
    email: "harut@sakeone.com",
    phone: "310-961-0483",
  },
];

interface Props {
  name: string;
  email: string;
  phone: string;
  disabled: boolean;
  onNameChange: (name: string) => void;
  onEmailChange: (email: string) => void;
  onPhoneChange: (phone: string) => void;
}

function ContactForm({
  name,
  email,
  phone,
  onNameChange,
  onEmailChange,
  onPhoneChange,
  disabled,
}: Props) {
  const options = defaultContacts
    .map((dc) => dc.name)
    .sort((a, b) => a.localeCompare(b));

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          renderInput={(params) => (
            <TextField {...params} label="Name" fullWidth />
          )}
          disabled={disabled}
          value={name}
          onChange={(_event, value, reason) => {
            if (reason === "selectOption") {
              const contact = defaultContacts.find((dc) => dc.name === value);
              if (contact) {
                onNameChange(contact.name);
                onEmailChange(contact.email);
                onPhoneChange(contact.phone);
              }
            }
          }}
          freeSolo
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MuiTelInput
          label="Phone"
          fullWidth
          value={phone}
          onChange={(value) => onPhoneChange(value)}
          disabled={disabled}
          defaultCountry="US"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email"
          fullWidth
          value={email}
          onChange={({ target: { value } }) => onEmailChange(value)}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export default ContactForm;
