import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Product } from "./hooks";

function trancateDescription(description: string) {
  if (description.length > 100) {
    return description.slice(0, 100) + "...";
  }
  return description;
}

interface Props {
  product: Product;
  customPrice?: string;
  disabled?: boolean;
  setCustomPrice: (product: Product, price: string) => void;
  removeCustomPrice: (product: Product) => void;
  removeProduct: (product: Product) => void;
}

const mediaWidth = 150;
const mediaHeight = mediaWidth * 1.5;

function ProductCard({
  product,
  setCustomPrice,
  removeCustomPrice,
  customPrice,
  removeProduct,
  disabled,
}: Props) {
  const onCustomPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: price },
    } = event;
    if (price !== "") {
      setCustomPrice(product, price);
    } else {
      removeCustomPrice(product);
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
      }}
    >
      <CardMedia
        component="img"
        image={product.image_url}
        alt=""
        sx={{
          width: mediaWidth,
          flex: 1,
          p: 2,
          minHeight: mediaHeight,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 2,
          width: `calc(100% - ${mediaWidth}px)`,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.2rem",
              fontWeight: 500,
            }}
          >
            {product.title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              fontWeight: 400,
            }}
          >
            {trancateDescription(product.description)}
          </Typography>
        </Box>

        <Stack direction="row" spacing={1}>
          <Chip label={`Alcohol: ${product.alcohol}`} />
          {/* TODO: `?` protects against old local caches of products pre-sizes.
              This should be safe to keep indefinitely, but can eventually be removed
              if necessary. The criteria for this is that the client no longer
              stores products in local storage, and has moved to a server-based
              cache.
          */}
          {product.sizes?.length && (
            <Chip label={`Sizes: ${product.sizes.join(", ")}`} />
          )}
        </Stack>

        <CardActions>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              label="Wholesale Price"
              variant="standard"
              onChange={onCustomPriceChange}
              value={customPrice ?? ""}
              disabled={disabled}
            />
            <IconButton
              disabled={disabled}
              aria-label="delete"
              onClick={() => {
                removeProduct(product);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </CardActions>
      </Box>
    </Card>
  );
}

export default ProductCard;
