import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";

const API_URL = import.meta.env.VITE_API_URL;

export interface Product {
  name: string;
  alcohol: string;
  description: string;
  image_url: string;
  title: string;
  id: number;
  sizes: string[];
}

/**
 * Fetches a list of products from the SakéOne API
 * @returns A list of products
 */
async function fetchProductPosts(): Promise<Product[]> {
  const res = await fetch(`${API_URL}/sales-sheet/v1/products`);
  const { products } = (await res.json()) as {
    products: {
      id: number;
      name: string;
      alcohol: string;
      description: string;
      image_url: string;
      sizes: string[];
    }[];
  };

  return products.map((product) => ({
    ...product,
    title: product.name,
  }));
}

/**
 * A hook for fetching a list of products from the API
 * @returns A react-query useQuery hook
 */
export function useProductPosts() {
  return useQuery<Product[]>({
    queryKey: ["product-posts"],
    queryFn: fetchProductPosts,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
}

type CustomPrices = Record<number, string>;

const PDF_STORAGE_KEY = "pdf-document";

interface PDFDocument {
  title: string;
  selectedProducts: Product[];
  customPrices: CustomPrices;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

const DEFAULT_DOCUMENT: PDFDocument = {
  title: "",
  selectedProducts: [],
  customPrices: {},
  contactName: "",
  contactPhone: "",
  contactEmail: "",
} as const;

interface SerializedPDFDocument {
  title: string;
  selectedProducts: number[];
  customPrices: CustomPrices;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

/**
 * Serializes a PDFDocument to a plain object for local storage
 * @param document A PDFDocument to serialize
 */
function serializePDFDocument(document: PDFDocument): SerializedPDFDocument {
  const {
    title,
    customPrices,
    selectedProducts,
    contactEmail,
    contactName,
    contactPhone,
  } = document;

  return {
    title,
    selectedProducts: selectedProducts.map((product) => product.id),
    customPrices,
    contactName,
    contactPhone,
    contactEmail,
  };
}

/**
 * Saves a PDFDocument to local storage
 * @param document A PDFDocument to save to local storage
 */
function savePDFDocument(document: PDFDocument) {
  const serialized = serializePDFDocument(document);
  localStorage.setItem(PDF_STORAGE_KEY, JSON.stringify(serialized));
}

interface LoadPDFDocumentResult {
  isStored: boolean;
  document: PDFDocument;
}

/**
 * Loads a PDFDocument from local storage if one exists
 * using the provided list of products to hydrate the document
 *
 * @param products A list of products to construct a valid PDFDocument from
 * @returns A hydrated PDFDocument if one exists in local storage, otherwise null
 */
function loadPDFDocument(products: Product[]): LoadPDFDocumentResult {
  const serialized = localStorage.getItem(PDF_STORAGE_KEY);

  if (!serialized) {
    return {
      isStored: false,
      document: DEFAULT_DOCUMENT,
    };
  }

  const parsed = JSON.parse(serialized) as SerializedPDFDocument;
  const selectedProducts: Product[] = [];
  for (const id of parsed.selectedProducts) {
    const product = products.find((product) => product.id === id);
    if (product) {
      selectedProducts.push(product);
    }
  }

  const parsedCustomPrices: CustomPrices = {};
  for (const [idStr, price] of Object.entries(parsed.customPrices)) {
    const id = parseInt(idStr, 10);
    if (selectedProducts.some((product) => product.id === id)) {
      parsedCustomPrices[id] = price;
    }
  }

  return {
    isStored: true,
    document: {
      title: parsed.title,
      selectedProducts,
      customPrices: parsedCustomPrices,
      contactName: parsed.contactName ?? "",
      contactPhone: parsed.contactPhone ?? "",
      contactEmail: parsed.contactEmail ?? "",
    },
  };
}

interface UsePersistedDocumentProps {
  enabled: boolean;
  products: Product[];
}

interface UsePersistedDocument {
  persistedDocument: PDFDocument;
  isLoaded: boolean;
}

/**
 * A hook for loading a PDFDocument from local storage
 */
function usePersistedDocument({
  enabled,
  products,
}: UsePersistedDocumentProps): UsePersistedDocument {
  const [persistedDocument, setPersistedDocument] =
    useState<PDFDocument>(DEFAULT_DOCUMENT);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      return;
    }

    const shouldHydrate = enabled && products.length;
    if (shouldHydrate) {
      const { isStored, document } = loadPDFDocument(products);
      if (isStored) {
        setPersistedDocument(document);
      } else {
        setPersistedDocument(DEFAULT_DOCUMENT);
      }

      setIsLoaded(true);
    }
  }, [isLoaded, enabled, products]);

  return {
    persistedDocument,
    isLoaded,
  };
}

interface UseAutoSaveProps {
  enabled: boolean;
  document: PDFDocument;
}

function useAutoSave({ enabled, document }: UseAutoSaveProps) {
  const timeoutRef = useRef<number | null>(null);

  const save = useCallback(() => {
    savePDFDocument(document);
    timeoutRef.current = null;
  }, [document]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(save, 500) as unknown as number;
  }, [document, enabled, save]);
}

interface UseDocumentStoreProps {
  products: Product[];
  enabled: boolean;
}

interface UseDocumentStore {
  isInitialized: boolean;
  document: PDFDocument;
  setContactName: (title: string) => void;
  setContactPhone: (phone: string) => void;
  setContactEmail: (email: string) => void;
  setSelectedProducts: (selectedProducts: Product[]) => void;
  setCustomPrice: (product: Product, price: string) => void;
  removeCustomPrice: (product: Product) => void;
  getCustomPrice: (product: Product) => string | undefined;
  removeProduct: (product: Product) => void;
  clear: () => void;
}

/**
 * A hook for hydrating and persisting a PDFDocument to local storage.
 * Reactively auto-saves the document to local storage when it changes.
 */
function useDocumentStore({
  products,
  enabled,
}: UseDocumentStoreProps): UseDocumentStore {
  const [isInitialized, setIsInitialized] = useState(false);

  // load from local storage on mount
  const { persistedDocument, isLoaded } = usePersistedDocument({
    enabled,
    products,
  });

  // initialize the document once it's loaded
  useEffect(() => {
    if (isInitialized) {
      return;
    }

    if (isLoaded) {
      setDocument(persistedDocument);
      setIsInitialized(true);
    }
  }, [isLoaded, isInitialized, persistedDocument]);

  const [document, setDocument] = useState<PDFDocument>(DEFAULT_DOCUMENT);

  // save on changes
  useAutoSave({ enabled: isInitialized, document });

  const setContactName = (name: string) => {
    setDocument((prev) => ({ ...prev, contactName: name }));
  };

  const setContactPhone = (phone: string) => {
    setDocument((prev) => ({ ...prev, contactPhone: phone }));
  };

  const setContactEmail = (email: string) => {
    setDocument((prev) => ({ ...prev, contactEmail: email }));
  };

  const setSelectedProducts = (selectedProducts: Product[]) => {
    setDocument((prev) => ({ ...prev, selectedProducts }));
  };

  const removeProduct = (product: Product) => {
    setDocument((prev) => {
      const updated = { ...prev };
      const index = updated.selectedProducts.findIndex(
        (selectedProduct) => selectedProduct.id === product.id,
      );
      if (index !== -1) {
        updated.selectedProducts.splice(index, 1);
      }
      return updated;
    });
  };

  const setCustomPrice = (product: Product, price: string) => {
    const { id } = product;
    setDocument((prev) => {
      const updated = { ...prev };
      updated.customPrices[id] = price;
      return updated;
    });
  };

  const removeCustomPrice = (product: Product) => {
    const { id } = product;
    setDocument((prev) => {
      const updated = { ...prev };
      delete updated.customPrices[id];
      return updated;
    });
  };

  const getCustomPrice = (product: Product) => {
    const { id } = product;
    return document.customPrices[id];
  };

  const clear = () => {
    const clearedDocument = {
      ...DEFAULT_DOCUMENT,
      contactName: document.contactName,
      contactPhone: document.contactPhone,
      contactEmail: document.contactEmail,
    };
    setDocument(clearedDocument);
    savePDFDocument(clearedDocument);
  };

  return {
    isInitialized,
    document,
    setContactName,
    setContactPhone,
    setContactEmail,
    setSelectedProducts,
    setCustomPrice,
    removeCustomPrice,
    getCustomPrice,
    removeProduct,
    clear,
  };
}

interface GeneratePdfOpts {
  products: Product[];
  customPrices: CustomPrices;
  contact_info: {
    name: string;
    phone: string;
    email: string;
  };
}

async function generatePdf({
  products,
  customPrices,
  contact_info,
}: GeneratePdfOpts) {
  const res = await fetch(`${API_URL}/sales-sheet/v1/pdf`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      products,
      custom_prices: customPrices,
      contact_info,
    }),
  });

  const fileName = "sales-sheet.pdf";

  const blob = await res.blob();
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
}

interface UseGeneratePdfOpts {
  products: Product[];
  customPrices: CustomPrices;
  onGenerateError: (error: unknown) => void;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

function useGeneratePdf({
  products,
  customPrices,
  onGenerateError,
  contactName,
  contactPhone,
  contactEmail,
}: UseGeneratePdfOpts) {
  return useMutation({
    mutationFn: async () =>
      generatePdf({
        products,
        customPrices,
        contact_info: {
          name: contactName,
          phone: contactPhone,
          email: contactEmail,
        },
      }),
    onError: onGenerateError,
  });
}

interface UsePDFDocumentOpts {
  onGenerateError: (error: unknown) => void;
}

interface UsePDFDocument {
  store: UseDocumentStore;
  products: Product[];
  isInitializing: boolean;
  isInitializationError: boolean;
  generateMutation: ReturnType<typeof useGeneratePdf>;
  retry: () => void;
  isReloading: boolean;
  reload: () => void;
}

export function usePDFDocument({
  onGenerateError,
}: UsePDFDocumentOpts): UsePDFDocument {
  const productsQuery = useProductPosts();

  const products = productsQuery.data ?? [];

  const store = useDocumentStore({
    products,
    enabled: productsQuery.isFetched,
  });

  const generateMutation = useGeneratePdf({
    products: store.document.selectedProducts,
    customPrices: store.document.customPrices,
    onGenerateError,
    contactName: store.document.contactName,
    contactPhone: store.document.contactPhone,
    contactEmail: store.document.contactEmail,
  });

  return {
    store,
    products,
    isInitializing: productsQuery.isFetching,
    isInitializationError: !!productsQuery.error,
    generateMutation,
    retry: productsQuery.refetch,
    isReloading: productsQuery.isRefetching,
    reload: productsQuery.refetch,
  };
}
