import * as Sentry from "@sentry/react";

const SENTRY_SAMPLE_RATE = import.meta.env.VITE_SENTRY_SAMPLE_RATE;
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing({}), Sentry.replayIntegration()],

  sampleRate: SENTRY_SAMPLE_RATE,

  tracesSampleRate: SENTRY_SAMPLE_RATE,

  tracePropagationTargets: ["localhost", /^https:\/\/api\.sakeone\.com/],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
