import MoreIcon from "@mui/icons-material/MoreVert";
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";

interface Props {
  isLoadingProducts: boolean;
  onReloadProducts: () => void;
}

function AppMenu({ isLoadingProducts, onReloadProducts }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <AppBar position="relative" sx={{ py: 2, px: 4, zIndex: 2 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img style={{ width: 60, marginRight: 30 }} src="/logo.svg" alt="" />

        <Typography
          variant="h1"
          sx={{
            fontSize: "1.5rem",
          }}
        >
          Sales Sheet Generator
        </Typography>

        <Box sx={{ flexGrow: 1, justifyContent: "flex-end", display: "flex" }}>
          <IconButton
            size="large"
            aria-label="menu"
            edge="end"
            color="inherit"
            ref={anchorRef}
            onClick={handleToggle}
            aria-controls="menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="menu"
            anchorEl={anchorRef.current}
            open={open}
            onClose={handleToggle}
          >
            <MenuItem
              onClick={() => {
                onReloadProducts();
                handleToggle();
              }}
              disabled={isLoadingProducts}
            >
              Reload Products
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </AppBar>
  );
}

export default AppMenu;
